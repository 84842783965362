import React from "react";
import { Container, Text } from "@atoms";

const CbQuote = ({ quote, citation }) => {
  return (
    <Container variant="xs" className="my-8">
      <div className="relative flex gap-2 pr-4 md:gap-4 md:pr-0">
        <span className="relative -top-1.5 text-[4rem] text-green md:-top-3 md:text-[8rem]">
          &ldquo;
        </span>
        <div className="flex flex-1 flex-col gap-4 border-b-2 border-r-2 border-black pr-6 pb-6 md:pr-12 md:pb-12">
          <Text className="text-xl font-bold !leading-tight md:text-4xl">
            {quote}
          </Text>
          <Text variant="body" className="font-bold">
            {citation}
          </Text>
        </div>
      </div>
    </Container>
  );
};

CbQuote.defaultProps = {};

export default CbQuote;
