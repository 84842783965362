import React from "react";
import classNames from "classnames";
import { Icon } from "@atoms";

const Logo = ({ scroll }) => {
  return (
    <div
      className={classNames("flex h-full items-center text-sm duration-300")}
    >
      <Icon
        name="logo"
        fitHeight
        className={classNames("w-auto text-white duration-300", {
          "h-24 w-24 text-white lg:h-28 lg:w-28": scroll < 300,
          "h-16 w-16 text-transparent": scroll > 300,
        })}
      />
    </div>
  );
};

export default Logo;
