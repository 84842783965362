import React from "react";
import classNames from "classnames";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import { useAppState } from "@state";
import { Text, Image, Icon } from "@atoms";
import { AppLink } from "@base";
import ModalContent from "./ModalContent";

const Wrapper = ({ condition, onClick, className: _className, children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={classNames(_className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const PersonCard = ({
  bio,
  email,
  image,
  link,
  mediums,
  name,
  phone,
  artistNumber,
  placeholder,
  position,
  type,
  tags,
  street,
  city,
  zip,
  lat,
  lon,
  website,
}) => {
  const [, dispatch] = useAppState();
  const lang = useLang();

  const triggerModal = e => {
    dispatch({
      type: "openModal",
      title: name,
      description: `${name}'s biography`,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(() => (
        <ModalContent
          heading={name}
          position={position}
          content={bio}
          image={image}
          tags={tags}
          mediums={mediums}
          street={street}
          city={city}
          zip={zip}
          lat={lat}
          lon={lon}
          website={website}
        />
      )),
    });
  };

  return (
    <Wrapper
      condition={type === "teachingArtists" ? link : false}
      onClick={bio ? triggerModal : false}
      className="group flex h-full w-full flex-row items-start gap-4 border-r-2 border-b-2 border-black sm:flex-col"
    >
      {(image || placeholder || type !== "default") && (
        <div
          className={classNames(
            "relative flex aspect-square w-24 items-center justify-center xxs:w-16 sm:w-32 sm:w-full",
            {
              "bg-gray": placeholder || type !== "default",
            }
          )}
        >
          {image && (
            <Image image={image} sizes={{ xs: 0.2, sm: 0.3, md: 0.2 }} fill />
          )}
          {(placeholder || (!image && type !== "default")) && (
            <Icon name="person" className="h-1/3 w-1/3" />
          )}
        </div>
      )}
      <div className="flex w-full flex-1 flex-col justify-between gap-4 pr-4 pb-4">
        <div className="flex flex-col gap-2 text-left">
          <Text className="text-lg font-bold leading-tight">
            {`${name}${artistNumber ? ` #${artistNumber}` : ""}`}
          </Text>
          {position && (
            <Text className="text-base leading-tight">{position}</Text>
          )}
          {!!mediums?.length && (
            <Text className="text-sm leading-tight">
              {mediums.map(medium => medium.title).join(", ")}
            </Text>
          )}
        </div>
        {(email || phone) && (
          <div className="flex flex-wrap justify-between gap-4 md:mt-4">
            {email && (
              <AppLink to={`mailto:${email}`}>
                <Text className="text-base font-bold">{email}</Text>
              </AppLink>
            )}
            {phone && <Text className="text-base font-bold">{phone}</Text>}
          </div>
        )}
        {(bio || (type === "teachingArtists" && link)) && (
          <span className="text-left text-base font-bold leading-none underline decoration-transparent decoration-2 underline-offset-4 duration-300 group-hover:decoration-green md:mt-4">
            {type === "teachingArtists"
              ? t("Read More", lang)
              : t("View Bio", lang)}
          </span>
        )}
      </div>
    </Wrapper>
  );
};

PersonCard.defaultProps = {};

export default PersonCard;
