import React from "react";

import Icon from "@svg/marker.svg";
import classNames from "classnames";

const Marker = ({ onClick, label }) => {
  return (
    <button
      type="button"
      className={classNames(
        "flex h-4 w-4 origin-bottom items-center justify-center text-green transition duration-300 hover:scale-125"
      )}
      onClick={onClick}
    >
      {label && (
        <span className="absolute top-0 -translate-y-3/4 whitespace-nowrap border-2 border-black bg-white py-0.5 px-1 text-xs font-bold leading-none text-black">
          {label}
        </span>
      )}
      <Icon />
    </button>
  );
};

export default Marker;
