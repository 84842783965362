import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import usePagination from "@hooks/usePagination";
import { Icon } from "@atoms";

const Pagination = ({
  className: _className,
  component: Component,
  data,
  pageSize,
  rangeLimit,
  showNavigation,
  variants,
  scrollTarget,
}) => {
  const pagination = useRef(null);
  const pages = Math.ceil((data?.length || 0) / pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  useEffect(() => {
    if (pagination.current)
      pagination.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [currentPage]);

  // chunk the data into pages
  const paginatedData = data?.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / pageSize);

    if (!result[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      result[chunkIndex] = []; // start a new chunk
    }

    result[chunkIndex].push(item);

    return result;
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const paginationRange = usePagination({
    pages,
    currentPage,
    rangeLimit,
  });

  // store page numbers in state to that the mapped data updates
  const [pageNumbers, setPageNumbers] = useState(paginationRange);

  useEffect(() => {
    setPageNumbers(paginationRange);
  }, [paginationRange]);

  const scrollTo = useCallback(target => {
    if (window) {
      document.getElementById(target)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  });

  const nextPage = useCallback(() => {
    setCurrentPage(page => (page < pages ? page + 1 : page));
    scrollTo(scrollTarget);
  }, [pages]);
  const prevPage = useCallback(() => {
    setCurrentPage(page => (page > 1 ? page - 1 : 1));
    scrollTo(scrollTarget);
  }, [pages]);
  // use the number within the button to set the page
  const changePage = useCallback(event => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
    scrollTo(scrollTarget);
  }, []);

  return (
    <>
      <div
        className="pointer-events-none relative -translate-y-[48px]"
        ref={pagination}
      />
      <ul className={classNames(_className)}>
        {paginatedData[currentPage - 1]?.map((item, i) => (
          <m.li
            key={item.uid}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
          >
            <Component order={i} page={currentPage} key={item?.uid} {...item} />
          </m.li>
        ))}
      </ul>
      {pages > 1 && (
        <div className="flex flex-wrap gap-4">
          {/* previous button */}
          {showNavigation && (
            <button type="button" onClick={prevPage} size="xs">
              <Icon
                name="chevron"
                className={classNames("h-4 w-4 rotate-180", {})}
              />
            </button>
          )}
          {/* page number buttons */}
          {pageNumbers?.map((pageNumber, i) => {
            if (pageNumber === "...") {
              return (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={pageNumber + i}
                  className="p-2 leading-tighter text-black"
                >
                  {pageNumber}
                </span>
              );
            }
            return (
              // using vanilla button here to allow for active button styling
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={pageNumber}
                type="button"
                className={classNames(
                  "rounded-none border-2 py-2 px-5 leading-tighter text-black transition-all duration-300  hover:rounded-[100px]",
                  {
                    "rounded-[100px] border-green bg-green":
                      currentPage === pageNumber,
                    "border-black": currentPage !== pageNumber,
                  }
                )}
                onClick={changePage}
              >
                <span>{pageNumber}</span>
              </button>
            );
          })}
          {/* next button */}
          {showNavigation && (
            <button type="button" onClick={nextPage} size="xs">
              <Icon name="chevron" className="h-4 w-4" />
            </button>
          )}
        </div>
      )}
    </>
  );
};

Pagination.defaultProps = {
  pageSize: 9,
  rangeLimit: 1,
  showNavigation: true,
};

export default Pagination;
